$grey-bg: #efefef;
$grey-col: #909090;
$green-bg: #29b347;

.badgeBase {
	color: #fff;
	padding: 0.1rem 0.625rem;
	border-radius: 0.3125rem;
	font-size: 0.875rem;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.badgeBlue {
	background-color: #4b9ad4;
}

.badgeRed {
	background-color: #f20000;
}

.badgeLight {
	color: #000;
	background-color: #f7f7f7;
}

.badgeGrey {
	color: $grey-col;
	background-color: $grey-bg;
}

.badgeDarkGrey {
	color: #fff;
	background-color: $grey-col;
}

.badgeGreen {
	color: #fff;
	background-color: $green-bg;
}

.badgeYellow {
	color: #fff;
	background-color: #fcb53b;
}

.selfClosing {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	margin: 0 0.625rem 0.625rem 0;
	font-size: 0.75rem;
	transition: all 0.5s;

	.closeBadge {
		width: 1rem;
		height: 1rem;
		margin-right: 0.625rem;
		border-radius: 50%;
		background-color: #fff;
		cursor: pointer;
		transform: rotate(45deg);
		position: relative;
		&::before {
			position: absolute;
			content: "";
			width: 0.625rem;
			height: 1px;
			background-color: #4b9ad4;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&::after {
			position: absolute;
			content: "";
			width: 1px;
			height: 0.625rem;
			background-color: #4b9ad4;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
