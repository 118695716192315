.box {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: space-between;
}

.left {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 61px;
	left: 0px;
	background: linear-gradient(
		270deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0) 92.31%
	);
	transition: all 0.3s;
	transform: rotate(-180deg);
	opacity: 0;

	& > p {
		margin: 0;
		font-size: 2rem;
		color: white;
	}
}

.right {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 61px;
	left: 0;
	transition: all 0.3s;
	opacity: 0;
	background: linear-gradient(
		270deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0) 92.31%
	);

	& > p {
		margin: 0;
		font-size: 2rem;
		color: white;
	}
}

.image {
	width: 270px;
	height: 270px;
}

.box {
	&:hover {
		.left,
		.right {
			opacity: 1;
		}
	}
}

.left:disabled {
	left: -50px;
}

.right:disabled {
	left: 50px;
}
