$main-color: #808080;

.formGroup {
	display: flex;
	flex-direction: column;
	color: $main-color;

	.titleWrapepr {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
	}

	.singleColumnChildren {
		display: flex;
	}

	h4 {
		font-size: 1rem;
		color: #393939;
		margin-top: 0;
		margin-bottom: 0;
		padding-right: 1.25rem;
	}

	.divider {
		height: 1px;
	}
}

@media (max-width: 640px) {
	.formGroup {
		.singleColumnChildren {
			flex-direction: column;
		}
	}
}
