.buttonBase {
	font-size: 1rem;
	font-family: inherit;
	font-weight: 500;
	padding: 0.875rem 1.5rem;
	cursor: pointer;
}

.buttonYellow {
	color: #363636;
	background-color: #fcb53b;
	transition: all 0.3s;

	&:hover {
		// background-color: #f8c771;
	}

	&:disabled {
		border: 1px solid #999999;
		background-color: #cccccc;
		color: #666666;
	}
}

.buttonDark {
	color: #fff;
	background-color: #363636;
}

.buttonBlue {
	color: #fff;
	background-color: #4b9ad4;
}

.buttonTransparent {
	background-color: transparent;
	padding: 0;
}

.buttonGrey {
	color: #808080;
	font-weight: 400;
	background-color: #fafafa;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.buttonDarkGrey {
	color: #fff;
	background-color: #cdcdcd;
}

.buttonRed {
	color: #fff;
	background-color: #f20000;
}

.buttonOrange {
	color: #fff;
	background-color: #ff9a6c;
}

.buttonMuted {
	color: #fff;
	background-color: #a3a3a3;
}

.buttonGreen {
	color: #fff;
	background-color: #29b347;
}
