.radioGroup {
	display: flex;
	& > div {
		margin-right: 1.875rem;
	}
}

.invalidFeedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #fcb53b;
}

@media (max-width: 640px) {
	.radioGroup {
		display: flex;
		flex-direction: column;
	}
}
