.filterItem {
	position: relative;
	color: #363636;
	font-weight: 600;
	border-bottom: 0.0625rem dashed rgba(0, 0, 0, 0.2);

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem 0.5rem;
		transition: all 0.5s;
		cursor: pointer;

		&:hover {
			background-color: #e5e5e5;
			border-left: 0.5rem #fcb53b solid;
		}
	}

	.checkboxTitle {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem 0.5rem;
		transition: all 0.5s;
	}

	.arrow {
		display: block;
		width: 0.625rem;
		height: 0.625rem;
		border-top: 0.125rem solid #fcb53b;
		border-right: 0.125rem solid #fcb53b;
		transform: rotate(45deg);
		transition: transform 0.3s;
		cursor: pointer;
	}

	.arrowRotated {
		transform: rotate(135deg);
	}

	.selectedFilters {
		padding-top: 1.25rem;
		display: flex;
		flex-wrap: wrap;
	}
}
.checkboxes {
	display: flex;
	flex-direction: column;
	font-size: 0.9375rem;
	font-weight: 400;

	label {
		display: flex;
		padding-left: 1rem;
		padding-bottom: 0.625rem;
		padding-top: 0.625rem;
	}
	input[type="radio"] {
		appearance: none;
		background: #fff;
		width: 1.25rem;
		height: 1.25rem;
		margin: 0;
		margin-right: 1rem;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		transition: 0.3s;
		cursor: pointer;
		&:checked {
			background: #4b9ad4;
		}

		// &:hover {
		// 	border: 3px #c9c9c9 solid;
		// }
	}

	input[type="checkbox"] {
		appearance: none;
		background: #fff;
		width: 1.25rem;
		height: 1.25rem;
		margin: 0;
		margin-right: 1rem;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		transition: 0.3s;
		cursor: pointer;
		&:checked {
			background: #4b9ad4;
		}

		// &:hover {
		// 	border: 3px #c9c9c9 solid;
		// }
	}
}

//<---

@keyframes szh-menu-show {
	from {
		opacity: 0;
	}
}

@keyframes szh-menu-hide {
	to {
		opacity: 0;
	}
}

.subMenu {
	display: block;
	background: #ffffff;
	font-weight: 400;
	position: absolute;
	width: 18.75rem;
	top: 0;
	left: 100%;
	visibility: hidden;
	z-index: 2;
	animation: szh-menu-hide 0.1s ease-out forwards;
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);

	&.show {
		visibility: inherit;
		animation: szh-menu-show 0.1s ease-out;
	}
}

.mapArrayItems {
	align-items: center;
	padding: 1.25rem 1rem;
	border-bottom: 1px dashed #e5e5e5;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		background-color: #e5e5e5;
		border-left: 0.5rem #fcb53b solid;
		transition: 0.3s;
	}
}

.searchInputConteiner {
	padding: 0.5rem 1rem;
}

.searchInput {
	box-sizing: border-box;
	background-color: rgba(99, 99, 99, 0.03);
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 0.3125rem;
	padding: 1rem 0.5rem 1rem 1rem;
	font-size: 1rem;
	font-weight: 400;
	width: 100%;
	//animation: szh-menu-hide 0.5s ease-out forwards;
	&:focus {
		border: 1px solid #fcb53b;
		animation: 0.5s ease-out;
	}

	&:hover {
		border: 1px solid #fcb53b;
		animation: 0.5s ease-out;
	}
}

.chekedCategoti {
	display: flex;
	flex-wrap: wrap;
}

.subMenuMain {
	max-height: calc(80vh - 140px);
	overflow: scroll;
}

.subMenuMainSecond {
	height: max-content;
	background-color: #fff;
}

.titleActive {
	background-color: #e5e5e5;
	border-left: 0.5rem #fcb53b solid;
}

.filterInput {
	height: 0;
	overflow: hidden;
	visibility: hidden;
	animation: szh-menu-hide 0.5s ease-out forwards;

	&.show {
		visibility: visible;
		height: max-content;
		animation: szh-menu-show 0.5s ease-out;
		padding-bottom: 0.75rem;
	}
}

.filterItemMobile {
	border-bottom: 0;

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1.25rem 0rem;
		font-weight: 500;
		font-size: 1.2rem;
		cursor: none;

		& > p {
			margin-right: 0.5rem;
		}
	}
}

.checkboxesitem {
	transition: 0.3s;
	cursor: pointer;
}

@media (max-width: 576px) {
	.checkboxes {
		flex-direction: row;
	}

	.filterItem {
		.checkboxTitle {
			display: none;
		}
	}
	.checkboxesitem {
		transition: 0.3s;
		cursor: pointer;
		color: #909090;

		& > input[type] {
			border-radius: 50%;
		}

		&:hover {
			background-color: #fff;
			border-left: 0;
		}
	}

	.filterItem {
		border-bottom: 1px solid transparent;

		.title {
			padding: 1.25rem 0rem;
		}
	}
}

.titleContainer {
	height: 2rem;
}

.desktopSmallContainer {
	height: 500px;
}

.seo {
	position: absolute;
	top: 0;
	left: 0;
	backdrop-filter: blur(1px);
	background: rgba(253, 253, 253, 0.74);
	// opacity: .5;
	z-index: 100;
	width: 100%;
	height: 6rem;
}
