@keyframes shake {
	0% {
		margin-left: 0rem;
	}
	25% {
		margin-left: 0.5rem;
	}
	75% {
		margin-left: -0.5rem;
	}
	100% {
		margin-left: 0rem;
	}
}

.input {
	width: 100%;
	padding: 0.75rem 1.125rem;
	font-size: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	background-color: #fafafa;

	&.isError {
		border: 1px solid #fcb53b;
		animation: shake 0.2s ease-in-out 0s 2;
		background-color: #f8f0e4;
		margin: 0;
	}
}

.select {
	appearance: none;
	@extend .input;
	outline: none;
	color: inherit;
	position: relative;
	width: 300px;

	option[value="None"] {
		display: none;
	}
}

.select-selected::after {
	position: absolute;
	content: "";
	width: 10px;
	height: 10px;
	background-color: yellowgreen;
	top: 0;
	left: 0;
}

.close {
	right: 32px;
	top: 32px;
	width: 32px;
	height: 32px;
	opacity: 0.3;
	cursor: pointer;
}
.close:hover {
	opacity: 1;
}
.close:before,
.close:after {
	left: 15px;
	content: " ";
	height: 33px;
	width: 2px;
	background-color: #333;
}
.close:before {
	transform: rotate(45deg);
}
.close:after {
	transform: rotate(-45deg);
}

.selectedItemWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	padding-top: 10px;
}
.itemRemoveButton {
	cursor: pointer;
}

.selectedItem {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	background: #4b9ad4;
	border-radius: 3px;
	padding: 7px 10px;
	color: #ffffff;
	grid-gap: 7px;
	gap: 7px;
}

.idealUseLabel {
	color: #393939;
	font-weight: 400;
	margin-bottom: 10px;
}
