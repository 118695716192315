@import "./../../../styles/module-global/colors.module.scss";
@import "./../../../styles/module-global/mixin.module.scss";

.priceInfoBase {
	display: flex;
	justify-content: space-between;
	.price {
		font-weight: 600;
	}
	.oldPrice {
		color: #808080;
		text-decoration: line-through;
	}
}

.priceInfoPrimary {
	display: flex;
	flex-direction: column;

	.price {
		color: black;
		font-size: 14px;
		font-weight: 700;
		line-height: 18.4px;
	}
	.oldPrice {
		color: #808080;
		font-size: 12px;
		font-weight: 400;
		line-height: 18.4px;
		margin-left: 5px;
	}
	.save {
		background-color: #f20000;
		color: #ffffff;
		font-size: 10px;
		font-weight: 600;
		line-height: 18.4px;
		padding: 0px 5px;
		margin: 0;
		border-radius: 0;
	}
}

.priceInfoSecondary {
	.price {
		color: inherit;
		font-size: 1.25rem;
		margin-right: 15px;
	}
	.oldPrice {
		font-size: 0.875rem;
		font-weight: 400;
	}
}

@keyframes shake {
	0% {
		top: 0;
	}
	25% {
		top: 5px;
	}
	75% {
		top: -5px;
	}
	100% {
		top: 0;
	}
}

.saleContainer {
	position: relative;
	display: flex;
	top: 0;
	left: 0;

	&.isError {
		animation: shake 0.2s ease-in-out 0s 2;
	}
}

.priceInfoMob {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 160px;
	align-items: flex-end;

	.price {
		margin: 0;
		padding: 0;
		@include text(12px, 600, 14.06px, $black);
		margin-right: 5px;
		margin-bottom: 0;
	}
	.oldPrice {
		@include text(10px, 400, 11.72px, $mediumGray);
		margin-right: 5px;
		margin-bottom: 0;
	}

	.save {
		@include text(8px, 400, 9.38px, $white);
		padding: 3px;
		height: max-content;
		width: max-content;
		border-radius: 2px;
		margin-bottom: 0;
	}
}
