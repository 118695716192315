@keyframes appear {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
}
.statesWindowsConteiner {
	display: table;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	z-index: 9999;
	// transform:scale(0);
	animation: appear 350ms ease-in 1;

	backdrop-filter: saturate(90%) blur(3px);
}

// position:fixed;
// display:table;
// height:100%;
// width:100%;
// top:0;
// left:0;
// transform:scale(0);

.statesWindowsModal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.5s;

	height: 70%;
	width: 25rem;
	overflow: auto;

	background-color: #fff;
	border-radius: 20px;
}

.badgeContainer {
}

.statesWindowsModalHeader {
	display: flex;
	position: sticky;
	width: 100%;
	padding: 1rem;
	top: 0;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	z-index: 300;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

	//transition: opacity 2s ease-out;

	& > p {
		font-size: 1.2rem;
		font-weight: 500;
		color: #fcb53b;
	}
}

.statesWindowsModalFooter {
	display: flex;
	position: sticky;
	width: 100%;
	bottom: 0;
	padding: 1rem;
	justify-content: center;
	background-color: #feb53d;
	color: black;
	font-size: 1.2rem;
	font-weight: 500;
	align-items: center;
	z-index: 300;
	cursor: pointer;
	box-shadow: 8px 4px 0px rgba(0, 0, 0, 0.1);
	transition: opacity 2s ease-out;
}

.closeBtn {
	font-size: 1.25rem;
	color: #b3b3b3;
	cursor: pointer;
	z-index: 250;
	cursor: pointer;
	transition: all 0.5s;

	&:hover {
		color: #feb53d;
	}
}

.arrow {
	display: block;
	width: 0.625rem;
	height: 0.625rem;
	border-top: 0.125rem solid #fcb53b;
	border-right: 0.125rem solid #fcb53b;
	transform: rotate(135deg);
	transition: transform 0.3s;
	cursor: pointer;
}

.titleContainer {
	transition: all 0.3s;
	cursor: pointer;
	border-bottom: 1px dashed rgb(165, 164, 164);

	&:hover {
		background-color: #e5e5e5;
		border-left: 0.5rem #fcb53b solid;
		transition: all 0.3s;
	}
}

.badgeContainer {
	display: flex;
	flex-wrap: wrap;

	background-color: aqua;
	border: 1px red solid;
}

.iconSearch {
	width: 1.5rem;
	margin-right: 1rem;
	& path {
		transition: all 0.3s;
		fill: black;
	}
}

.statesWindowsModalFooter {
	transition: all 0.3s;

	& > div {
		transition: all 0.3s;
	}

	&:hover {
		background-color: white;
		box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
	}

	&:hover .iconSearch {
		& path {
			fill: #feb53d;
		}
	}

	&:hover > div {
		color: #feb53d;
	}
}

.onFormSubmit {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.categoriesSpecifikBox {
	display: flex;
	flex-wrap: wrap;
}

.categoriesSpecifik {
	width: max-content;
}
