@import "./../../../styles/module-global/colors.module.scss";
@import "./../../../styles/module-global/mixin.module.scss";

@-webkit-keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

@keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

.noResult {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 1rem;

	-webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.image {
	margin-top: 100px;
	width: 100%;
	max-width: 428px;
	height: 100%;
	max-height: 291px;
}

.text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	max-width: 428px;
	text-align: center;

	& > p {
		margin: 0;
		padding: 0;
	}

	& > p:first-child {
		@include text(26px, 700, 30.47px, $black);
		margin-bottom: 10px;
	}

	& > p:last-child {
		@include text(16px, 400, 18.75px, $mediumGray);
		margin-bottom: 50px;
	}
}

.searchBar {
	width: 100%;
	max-width: 800px;
	height: 57px;
	margin-bottom: 100px;

	& > input {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 20px;
		@include text(16px, 400, 18.75px, $black);

		background: #ffffff;
		border: 1px solid #e5e5e5;
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
		transition: 0.5s all;
	}

	& > input::placeholder {
		color: $mediumGray;
	}

	& > input:hover,
	& > input:focus {
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: -5px;
	transform: translate(-50%, -50%);

	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	width: 35px;
	height: 35px;
	border-radius: 50%;
	background-color: transparent;
	transition: 0.5s all;

	&:hover {
		background-color: $whiteGrey;
	}
}
