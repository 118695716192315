.formField {
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	position: relative;
	margin-right: 1.25rem;
	margin-bottom: 1.25rem;

	&:last-child {
		margin-right: 0;
	}

	.label {
		color: #393939;
		font-weight: 400;
	}
}

.columnDirection {
	flex-direction: column;
	align-items: flex-start;
}

.rowDirection {
	flex-direction: row;
}

.labelOrderFirst label {
	order: -1;
}
.labelOrderLast label {
	order: 1;
}

.invalidFeedback {
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #fcb53b;
}
