@keyframes appear {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
}

.statesWindowsConteiner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	z-index: 9999;
	transition: all 0.5s;
	animation: appear 350ms ease-in 1;

	backdrop-filter: saturate(90%) blur(3px);
}

.statesWindowsModal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.5s;

	height: max-content;
	width: 40%;
	overflow: auto;

	background-color: #fff;
	border-radius: 20px;
}

.badgeContainerMap {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 70%;
	margin: 0 auto;
	margin-top: 1rem;
}

.statesWindowsModalHeader {
	display: flex;
	position: sticky;
	width: 100%;
	padding: 1rem;
	top: 0;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	z-index: 300;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

	& > p {
		font-size: 1.2rem;
		font-weight: 500;
		color: #fcb53b;
	}
}

.statesWindowsModalFooter {
	display: flex;
	position: sticky;
	width: 100%;
	bottom: 0;
	padding: 1rem;
	justify-content: center;
	background-color: #feb53d;
	color: black;
	font-size: 1.2rem;
	font-weight: 500;
	align-items: center;
	z-index: 300;
	cursor: pointer;
	box-shadow: 8px 4px 0px rgba(0, 0, 0, 0.1);
	transition: all 0.5s;
}

.closeBtn {
	font-size: 1.25rem;
	color: #b3b3b3;
	cursor: pointer;
	z-index: 250;
	cursor: pointer;
	transition: all 0.5s;

	&:hover {
		color: #feb53d;
	}
}

.iconSearch {
	width: 1.5rem;
	margin-right: 1rem;
	& path {
		transition: all 0.3s;
		fill: black;
	}
}

.statesWindowsModalFooter {
	transition: all 0.3s;

	& > div {
		transition: all 0.3s;
	}

	&:hover {
		background-color: white;
		box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
	}

	&:hover .iconSearch {
		& path {
			fill: #feb53d;
		}
	}

	&:hover > div {
		color: #feb53d;
	}
}

.onFormSubmit {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}

.map {
	width: 90%;
	margin: 0 auto;
}

.mountain {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.southeast {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.northeast {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.greatLakes {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.midwestSouth {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.midwestNorth {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.pacific {
	fill: #cfcdcd;
	transition: all 0.3s;

	&:hover {
		fill: #4b9ad4;
	}
}

.active {
	fill: #4b9ad4;
}

.pacificRegoin:hover .pacific {
	fill: #4b9ad4;
}

.southeastRegion:hover .southeast {
	fill: #4b9ad4;
}

.mountainRegion:hover .mountain {
	fill: #4b9ad4;
}

.midwestSouthRegion:hover .midwestSouth {
	fill: #4b9ad4;
}

.midwestNorthRegion:hover .midwestNorth {
	fill: #4b9ad4;
}

.northeastRegion:hover .northeast {
	fill: #4b9ad4;
}

.greatLakesRegion:hover .greatLakes {
	fill: #4b9ad4;
}

@keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
	opacity: 0;
}

.mapSelect {
	position: relative;
	border-radius: 10px;

	width: 100%;
	height: 1rem;
	margin: 0 auto;
	padding: 0.5rem;
}

.mapTitle {
	position: absolute;
	top: 0;
	left: 40%;
	transform: translate(0, 40%);
	color: #feb53d;
	font-size: 1.5rem;
	margin: 0;
	transition: all 0.3s;
}

.fadeInUp {
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
}

.mapTitleSeo {
	position: absolute;
	top: -200%;
	left: 40%;
	transform: translate(0, 40%);
	color: #feb53d;
	font-size: 2rem;
	margin: 0;
	transition: all 0.3s;
}
