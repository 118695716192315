.popup {
	// width: 100%;
}

.popupContent {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 200;
}
