.conteiner {
	display: flex;
	flex-wrap: wrap;
	padding: 1.25rem 0.5rem;

	border-bottom: 0.0625rem dashed rgba(0, 0, 0, 0.2);

	transition: 0.3s;
	cursor: pointer;
}

.button {
	height: 36px;
	display: flex;
	width: 100%;
	margin-left: 1rem;
}

.title {
	font-size: 1rem;
	font-weight: 500;
	padding-bottom: 1rem;
	line-height: 19px;
}

.number {
	font-size: 1rem;
	font-weight: 500;
	margin-top: 1rem;
	line-height: 19px;
	padding: 0.5rem;

	display: none;
}

@media (max-width: 576px) {
	.conteiner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 25px 0 0;
		margin-bottom: 10px;
		border-bottom: 1px solid transparent;

		&:hover {
			background-color: #ffff;
			border-left: 0;
			transition: 0;
		}
	}

	.button {
		display: none;
	}

	.title {
		font-size: 1.1rem;
		font-weight: 500;
		padding-bottom: 1rem;
		line-height: 19px;

		display: none;
	}

	.number {
		font-size: 1rem;
		font-weight: 500;
		margin-top: 1rem;
		line-height: 19px;
		padding: 0.5rem;

		display: none;
	}
}
