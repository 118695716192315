
.cardsWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

@media (max-width: 900px) {
	.cardsWrapper {
		width: 90%;
	}
}

.headerWrapper {
	display: flex;
	align-items: center;
	margin-bottom: 3.75rem;
	h3 {
		margin: 0 1.875rem;
	}
}

.buttonWrapper {
	display: flex;
	justify-content: center;
	margin-top: .5rem;
	margin-bottom: 3.5rem;
}

@media (max-width: 700px) {
	.cardsWrapper {
		width: 330px;
		margin: 0 auto;
		padding-left: 1rem;
	}

	.headerWrapper {
		display: flex;
		align-items: center;
		margin-bottom: 0rem;
		margin-top:0rem;
		h3 {
			margin: 0 1.875rem;
			margin-bottom: 1.75rem;
			margin-top: 1rem;
		}
	}

	.button {
		max-width: 330px;
	}

	.buttonWrapper {
		display: flex;
		justify-content: center;
		margin-top: .5rem;
		margin-bottom: 2.5rem;
	}
}

.swiperBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 1300px;
}

.swiperButtonLeft,
.swiperButtonRight {
	max-width: 2rem;
	// padding: .5rem;
	cursor: pointer;
}

@media (max-width: 700px) {
	.swiperBox {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 90%;
		position: relative;
		margin: 0 auto;
	}

	.swiperButtonLeft {
		// position: absolute;
		// bottom: -2rem;
		// left: 8rem;
	}

	.swiperButtonRight {
		// position: absolute;
		// bottom: -2rem;
		// right: 8rem;
	}
}

.singleElement {
	display: flex;
	max-width: max-content;
	margin: 0 auto;
}

.cardsWrapperTwo {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 100%;
}

@media (max-width: 700px) {
	.cardsWrapperTwo {
		flex-direction: column;
	}
}

.swiperButtonRight {
	margin-left: 0rem;
}

@media (max-width: 1399px) {
	.swiperButtonRight {
		margin-left: 0;
	}
}




@media (max-width: 440px) {
	.swiperBox {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 90%;
		position: relative;
		margin: 0 auto;
	}

	.swiperButtonLeft {
		position: absolute;
		bottom: -3rem;
		left: 1rem;
	}

	.swiperButtonRight {
		position: absolute;
		bottom: -3rem;
		right: 1rem;
	}

	.cardsWrapper {
		width: 290px;
		margin-right: 15%;
	}
}