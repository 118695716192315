.inventoryInfoList {
	.listItem {
		width: 100%;
		display: flex;
		align-items: flex-start;
		margin-bottom: 1.25rem;
	}

	.key {
		color: #808080;
		white-space: nowrap;
	}

	.line {
		flex-grow: 1;
		border-top: 1px dashed rgba(0, 0, 0, 0.15);
		margin: 0 1rem;
		padding-bottom: 0.25rem;
		position: relative;
		top: 1rem;
	}

	.value {
		color: #363636;
		text-align: right;
	}

	.bottomMargin {
		margin-bottom: 2.75rem;
	}

	.topMargin {
		margin-top: 2.75rem;
	}
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(20px);
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
	}
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
}

.animatedfadeInRight {
	opacity: 0;
}

.fadeInRight {
	opacity: 0;
	animation-name: fadeInRight;
	-webkit-animation-name: fadeInRight;
}

.listIdealUse {
	display: flex;
	flex-direction: column;

	& > div {
		margin-bottom: 0.5rem;
	}
}
