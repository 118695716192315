@keyframes appear {
	0% {
		opacity: 0;
		//transform: translateY(-10px);
	}
}

div.newLoadPopup {
	// 	left: 50%;
	// 	top: 50%;
	// 	transform: translate(-50%, -50%);
	//	padding-top: 10rem;

	top: 50%;
	animation: appear 200ms ease-in 1;
}

.newLoadFormGroup {
	margin-bottom: 2.5rem;
	padding-right: 1.5rem;

	& label {
		margin-bottom: 10px;
	}
}

.noMargin {
	margin-bottom: 0;
}

.toggleWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: -0.5rem;

	label {
		margin-left: 0.5rem;
		margin-bottom: 0;
	}
}

.threeRadiosAndInput {
	display: grid;
	grid-template-columns: 5fr 2fr 2fr;
}

.twoRadiosAndInput {
	display: grid;
	grid-template-columns: 220px calc(100% - 200px);
	column-gap: 40px;
}

.fourInputs {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 20px;
	// align-items: end;
}

.threeInputs {
	display: grid;
	grid-template-columns: repeat(3, 300px);
	column-gap: 20px;
	// align-items: end;
}

.oneInput {
	display: grid;
	grid-template-columns: 300px;
}

.flexBox {
	display: flex;
	align-items: baseline;
	gap: 10px;
	width: 80%;
}

.secondFlex {
	display: grid;
	grid-template-columns: 300px auto;
	column-gap: 20px;
}

.selectGroup {
	& select {
		margin-bottom: 1.25rem;
	}
}

@media (max-width: 1140px) {
	.fourInputs,
	.threeInputs {
		grid-template-columns: repeat(3, 250px);
	}

	.oneInput {
		// margin-left: 270px;
	}
}

@media (max-width: 970px) {
	.fourInputs,
	.threeInputs {
		grid-template-columns: repeat(2, 250px);
	}

	.toggleWrapper {
		justify-content: flex-start;
		order: -1;
		margin-bottom: 1rem;
	}

	.oneInput {
		margin-left: 0;
		display: flex;
	}

	.threeRadiosAndInput {
		display: flex;
		flex-direction: column;
	}

	.secondFlex {
		display: flex;
		flex-direction: column;
	}

	.noMargin {
		width: 100%;
	}
}

.invalidFeedback {
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #fcb53b;
	display: flex;
	align-items: center;
}

@media (max-width: 970px) {
	.fourInputs,
	.threeInputs {
		display: flex;
		flex-direction: column;
	}
}

div.formContent {
	max-height: 70vh;
	overflow-y: scroll;
}

.selectedCategoryWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.selectedCategory {
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	background: #4b9ad4;
	border-radius: 3px;
	padding: 7px 10px;
	color: #ffffff;
	gap: 7px;
}

@media (max-width: 640px) {
	div.newLoadPopup {
		top: 50%;

		// what the f* is going on with this container?!

		// position: absolute;
		// top: unset;
		// left: unset;
		// transform: unset;
	}

	div.newLoadFormContainer {
		width: 90%;
		margin: 0 auto;
		padding: 0;
		border-radius: 0;
		// background-color: #f5f5f5;
		background-color: transparent;

		form {
			padding: 0 20px;
		}

		div.formHeader {
			margin-bottom: 0;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			top: 0;
			position: sticky;
			width: 100%;
			margin: 0 auto;

			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			h3 {
				font-size: 20px;
				padding: 20px;
			}

			.mobileSubtitle {
				color: #636363;
			}

			svg {
				top: 20px !important;
				right: 20px !important;
			}
		}

		.twoRadiosAndInput {
			display: unset;
			grid-template-columns: unset;
			column-gap: unset;
		}
	}

	div.formContent {
		max-height: 80vh;
		overflow-y: auto;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;

		background-color: #f5f5f5;
	}
}

.availableForExport {
	display: flex;
	align-items: center;
	margin: 0;
	margin-bottom: 1rem;
	width: 95%;
}

.newLoadFormGroupUp {
	display: flex;
	margin: 0;
	width: 100%;
}

.inputUpper {
	display: flex;
	width: 100%;
}

@media (max-width: 940px) {
	.inputUpper {
		display: flex;
		flex-direction: column;
	}
}

.leadTimeInput {
	display: flex;
}

.leadTime {
	margin-right: 2rem;
	display: flex;
	flex-direction: column;
	width: 30rem;

	& > p {
		width: 10rem;
		margin: 0 0 0.7rem 0;
	}
}

@media (max-width: 540px) {
	.availableForExport {
		flex-direction: column;
	}

	.leadTime {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 24px 0 0;

		& > p {
			width: 10rem;
			margin: 0 0 0.7rem 0;
		}
	}
}

.NotesAllert {
	display: flex;
	align-items: center;

	& > p {
		color: #fcb53b;
		font-size: 1rem;
		font-weight: 450;
		margin: 0.5 0 0.5rem 0;
	}
}

/* glass orb */
.orb {
	position: relative;
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	margin: 0 1rem;
	background: #fcb53b;
	background-image: radial-gradient(
		farthest-corner at 34px 14px,
		#fcb53b 25%,
		#fcb53b 35%,
		#fcb53b 100%
	);
	box-shadow: 0 0 20px 5px #fcb53b;
	animation: orb 1.8s infinite;
}

/* star shaped shine pattern */
.shine {
	position: absolute;
	top: 3px;
	left: 6px;
	width: 1px;
	height: 16px;
	background: #fcb53b;
	transform: rotate(15deg);
	box-shadow: 0 0 8px rgba(255, 255, 255, 0.75);
	animation: shine 10s infinite;
}
.shine:before,
.shine:after {
	content: "";
	display: block;
	position: absolute;
}
.shine:before {
	top: 2px;
	left: -2px;
	width: 1px;
	height: 16px;
	background: #fcb53b;
	transform: rotate(-90deg);
	box-shadow: #fcb53b;
}
.shine:after {
	top: 6px;
	left: -2px;
	width: 5px;
	height: 5px;
	background: #fcb53b;
	transform: rotate(45deg);
	box-shadow: #fcb53b;
}

/* animates orb glow */
@keyframes orb {
	0%,
	100% {
		box-shadow: 0 0 5px 2px #fcb53b;
	}
	50% {
		box-shadow: 0 0 24px 4px #fcb53b;
	}
}
/* rotates shine */
@keyframes shine {
	0% {
		opacity: 0;
	}
	34% {
		opacity: 0;
		transform: rotate(15deg);
	}
	40% {
		opacity: 0.6;
		transform: rotate(620deg);
	}
	48%,
	100% {
		opacity: 0;
		transform: rotate(635deg);
	}
}

.palletHeightAverage {
}

@media (max-width: 1140px) {
	.palletHeightAverageDIV {
		display: none;
	}
}

.loadCondition {
	border: 1px solid red;

	display: flex;
	flex-direction: column-reverse;
}

.loadConditionInfo {
	border: 1px solid red;

	display: flex;
	margin-bottom: 0.5rem;

	& > p {
		margin: 0;
		margin-right: 1rem;
	}
}

.conditionTypePopup {
	width: 10rem;
	height: 10rem;
}
