@keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
	opacity: 0;
}

.fadeInUp {
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
}

.loadCard {
	margin-left: 1.875rem;
	display: grid;
	grid-template-columns: 300px auto;
	margin-bottom: 2.5rem;
	transition: all 0.3s;
	padding: 1rem;
	cursor: pointer;
	width: 100%;

	&:hover {
		transform: scale(1.05);
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
	}

	&:hover .loadImg {
		transform: scale(1);
	}
}

.imgWrapper {
	position: relative;
	width: max-content;
	height: max-content;

	.imgStatusBadge {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 75%;
		width: max-content;
	}
}

.priceSection {
	margin-top: 2rem;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.loadStatusBadge {
	height: 75px;
	width: 140px;
	color: #fff;
	font-size: 50px;
}

.loadCardDescription {
	display: flex;
	flex-direction: column;
	margin-left: 1.875rem;

	h4 {
		display: flex;
		margin-top: 0;
		margin-bottom: 1.125rem;
	}

	.loadPriceInfo {
		justify-content: flex-start;

		& > div:first-child {
			font-size: 1.25rem;
			color: #000;
		}

		& > div:last-child {
			margin-left: 1rem;
			font-size: 0.875rem;
		}
	}

	.cardFotter {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
		flex-wrap: wrap;
		align-items: flex-end;
		height: 100%;
		padding-bottom: 0.2rem;

		.acions {
			display: flex;
		}
	}

	.actionBtn {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		&.w130 {
			width: 130px;
			margin: 0;
			transition: all 0.3s;
			margin-top: 0.5rem;
		}
	}
}

@media (max-width: 1400px) {
	.loadCardDescription {
		margin-bottom: 30px;
		margin-left: 1rem;
	}

	.loadPriceInfo {
		margin-bottom: 30px;
	}
}

@media (max-width: 992px) {
	.loadCard {
		display: flex;
		flex-direction: column;
	}
	.loadCardDescription {
		margin-left: 0;
		margin-top: 1.875rem;
	}
}

@media (max-width: 800px) {
	.loadCard {
		margin-left: 1rem;
		padding: 0;
		padding-left: 1rem;
	}

	.imgWrapper {
		width: 250px;
	}
}

@media (max-width: 650px) {
	.imgWrapper {
		width: 180px;
	}

	.priceSection {
	}
}

@media (max-width: 576px) {
	.loadCard {
		margin-left: 0;
		height: max-content;
		max-width: 330px;

		&:hover {
			transform: scale(1);
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
		}
	}
	.loadCardDescription > p {
		margin-bottom: 2rem;
	}
	.imgWrapper {
		width: max-content;
	}
}

.icon,
.iconLink {
	transition: all 0.3s;
	&:hover {
		transform: scale(1.3);
	}
}

.iconLink {
	position: relative;
	display: inline-block;
}

.iconLink .tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: #fff;
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 125%;
	left: -13%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 0.6rem;

	font-size: 0.7rem;
	font-weight: 500;
}

.iconLink .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
}

.iconLink:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipLink {
	margin-top: 0.3rem;
	color: rgb(30, 103, 238);
	text-decoration: underline;
}

.imgWrapper {
	position: relative;

	.infoBtn {
		position: absolute;
		top: 1.25rem;
		right: 1.25rem;
		padding: 5px;
		z-index: 1;
	}
}

.infoBtn .tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: rgb(243, 196, 65, 0.5);
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: -85%;
	right: 125%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 0.6rem;

	font-size: 0.7rem;
	font-weight: 500;

	opacity: 0;
}

.infoBtn .tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 50%;
	right: -5%;
	margin-left: -5px;
	transform: rotate(270deg);
	border-width: 5px;
	border-style: solid;
	border-color: rgb(243, 196, 65, 0.5) transparent transparent transparent;
}

.infoBtn:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipMessage {
	width: 12rem;
	font-size: 0.9rem;
}

@media (max-width: 350px) {
	.loadCard .imgWrapper {
		max-width: 300px;
		margin: 0 auto;
	}
}

@media (max-width: 370px) {
	.priceSection {
		margin-left: auto;
		margin-right: auto;
	}

	.cardFotter {
		flex-direction: column;
		justify-content: center;
		width: max-content;
		margin: 0 auto;
		align-items: center !important;
	}
}

@media (max-width: 320px) {
	.loadCard .imgWrapper {
		max-width: 270px;
	}
}

@media (max-width: 300px) {
	.loadCard .imgWrapper {
		max-width: 220px;
	}
}

.loadText {
	margin-top: 0.5rem;
}

.loadImg {
	transform: scale(1.1);
	transition: all 0.3s;
}

.quantity {
	display: flex;
	flex-direction: column;
	width: max-content;
	align-items: flex-end;

	& > p {
		color: #808080;
		margin: 0;

		margin-bottom: 0.5rem;
	}
}

.badge {
	display: flex;
	align-items: center;
	justify-content: center;
	width: max-content;

	padding: 0.5rem 1.5rem 0.5rem 1.5rem;
	background-color: #f7f7f7;
	border-radius: 5px;

	font-weight: 600;
	font-size: 1rem;
}

@media (max-width: 1200px) {
	.priceSection {
		display: flex;
		flex-direction: column;
	}

	.quantity {
		display: flex;
		flex-direction: row;

		margin-top: 1rem;

		& > p {
			margin-right: 1rem;
		}
	}
}
