@keyframes shake {
	0% {
		margin-left: 0rem;
	}
	25% {
		margin-left: 0.5rem;
	}
	75% {
		margin-left: -0.5rem;
	}
	100% {
		margin-left: 0rem;
	}
}

.input {
	width: 100%;
	padding: 0.75rem 1.125rem;
	font-size: 1rem;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	background-color: #fafafa;

	&.isError {
		border: 1px solid #fcb53b;
		animation: shake 0.2s ease-in-out 0s 2;
		background-color: #f8f0e4;
		margin: 0;
	}
}

.input[disabled] {
	background-color: #eaeaea;
	color: #eaeaea;
}

.input[type="radio"],
.input[type="checkbox"] {
	padding: 0;
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.input[type="radio"] + label,
.input[type="checkbox"] + label {
	display: inline-flex;
	align-items: center;
	user-select: none;
	position: relative;
}

.input[type="radio"] + label::before,
.input[type="checkbox"] + label::before {
	content: "";
	display: inline-block;
	width: 22px;
	height: 22px;
	flex-shrink: 0;
	flex-grow: 0;
	border: 1px solid rgba(0, 0, 0, 0.25);
	margin-right: 1.125rem;
}

.input[type="radio"]:checked + label::after,
.input[type="checkbox"]:checked + label::after {
	content: "";
	width: 20px;
	height: 20px;
	background-color: #fcb53b;
	position: absolute;
	top: 50%;
	left: 2px;
	transform: translateY(-50%);
}

.input[type="radio"] + label::before,
.input[type="radio"]:checked + label::after {
	border-radius: 50%;
}

.select {
	appearance: none;
	@extend .input;
	outline: none;
	color: inherit;
	position: relative;
}

.select-selected::after {
	position: absolute;
	content: "";
	width: 10px;
	height: 10px;
	background-color: yellowgreen;
	top: 0;
	left: 0;
}

.textareaWrapper {
	width: 100%;
}

.textarea {
	@extend .input;
	width: 100%;
	height: 80px;
	font-family: inherit;
	font-size: inherit;
	resize: none;
}
