.singleInventoryContent {
	display: flex;
	margin-bottom: 5rem;
	margin-top: 1.5rem;

	padding-top: 5rem;

	.galleryWrapper {
		position: relative;

		.infoBtn {
			position: absolute;
			top: 1.25rem;
			right: 1.25rem;
			padding: 5px;
			z-index: 1;
		}
	}

	// .gallery {
	// 	display: flex;
	// 	flex-direction: column;

	// 	.currentPictureWrapper {
	// 		width: 31.25rem;
	// 		height: 31.25rem;
	// 		margin-bottom: 0.8125rem;
	// 	}

	// 	.thumbnails {
	// 		display: flex;
	// 		justify-content: space-between;
	// 	}

	// 	.thumbnailWrapper {
	// 		width: 115px;
	// 		height: 115px;
	// 		position: relative;
	// 		cursor: pointer;
	// 		.pale {
	// 			position: absolute;
	// 			width: 115px;
	// 			height: 115px;
	// 			background-color: rgba(255, 255, 255, 0.4);
	// 			top: 0;
	// 			left: 0;
	// 			color: #000;
	// 			font-size: 1.5rem;
	// 			font-weight: 600;
	// 			pointer-events: none;
	// 			transition: all ease 0.2s;
	// 			display: flex;
	// 			justify-content: center;
	// 			align-items: center;
	// 		}
	// 		&:hover .pale {
	// 			background-color: unset;
	// 		}
	// 	}
	// }
}

.singleInventoryInfo {
	display: flex;
	flex-direction: column;
	margin-left: 7.5rem;
	max-width: 680px;

	h3 {
		margin-bottom: 2.25rem;
	}

	.bagesWrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.badge {
		margin-right: 0.875rem;
		margin-bottom: 0.875rem;
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}

	.priceInfo {
		max-width: 21.5rem;
		margin-bottom: 1.875rem;

		& > div {
			font-size: 2rem;
			margin-bottom: 20px;

			& > div {
				padding: 10px;
				font-size: 1.5rem;
			}

			& > span {
				font-size: 1.5rem;
			}
		}
	}

	.manifestSection {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 1rem;
		.manifestLink {
			color: #042bfa;
			text-decoration: underline;
			cursor: pointer;
		}

		// .noActive {
		// 	color: gray;
		// }
	}

	.quantity {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1.875rem;
		margin-bottom: 40px;
		.badge {
			padding: 0.8125rem 1.8125rem;
			font-size: 1.25rem;
			font-weight: 600;
		}
	}

	.actions {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1.25rem;
		margin-bottom: 2.5rem;
	}

	.headerWrapper {
		display: flex;
		align-items: center;
		margin-bottom: 1.25rem;
		h4 {
			margin: 0;
			margin-right: 1.875rem;
		}
	}

	.conditionBadges {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 2.25rem;
	}
}

.similarProducts {
	.headerWrapper {
		display: flex;
		align-items: center;
		margin-bottom: 3.75rem;
		h3 {
			margin: 0 1.875rem;
		}
	}
	.cardsWrapper {
		display: flex;
		justify-content: center;
		position: relative;
		& > div:first-child {
			margin-left: 0;
		}

		.paginationSingleInvenory {
			position: absolute;
			width: calc(100% + 140px);
			justify-content: space-between;
			bottom: 160px;
		}
	}
	.buttonWrapper {
		display: flex;
		justify-content: center;
		margin-top: 1.75rem;
		margin-bottom: 3.5rem;
	}
}

@media (max-width: 992px) {
	.singleInventoryContent {
		flex-direction: column;
		margin: 0 auto;
	}

	.singleInventoryInfo {
		margin-left: 0;
		h3 {
			margin-top: 1.75rem;
			margin-bottom: 1.875rem;
			font-size: 1.25rem;
			line-height: 1.875rem;
		}

		.badge {
			margin-right: 0.625rem;
			margin-bottom: 0.625rem;
		}

		.manifestSection {
			flex-direction: column;
			align-items: baseline;
			.text {
				margin-top: 0.625rem;
				margin-bottom: 0.625rem;
			}
			.textWrapper {
				margin-bottom: 1.25rem;
			}
		}

		.quantity {
			.text {
				margin: 0.375rem 0;
			}
			.badge {
				margin-bottom: 0;
			}
		}

		.headerWrapper {
			margin-bottom: 1rem;
		}

		.conditionBadges {
			margin-bottom: 1.875rem;
		}
	}

	.similarProducts {
		display: flex;
		flex-direction: column;

		.cardsWrapper .paginationSingleInvenory {
			width: unset;
			bottom: -3.75rem;
		}

		.buttonWrapper {
			margin-top: 7.5rem;
			margin-bottom: 3.75rem;
		}
	}
}

@media (max-width: 640px) {
	.singleInventoryContent {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}
}

.test {
	background-color: #363636;
	padding: 1rem 4rem 1rem 4em;
	display: none;
}

@media (max-width: 440px) {
	.test {
		padding: 0;
		display: none;
	}

	.galleryBox {
		width: 100%;
	}
}

.sizeDescriptionText {
	font-weight: 400;
	font-size: 1rem;
	line-height: 26px;
	color: #808080;
	margin-top: 1rem;
	margin-bottom: 3rem;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	& > span {
		color: #363636;
		font-weight: 500;
		font-size: 1.1rem;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

.textWrapper {
	position: relative;
	display: inline-block;
}

.textWrapper .tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: #fff;
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 40%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 0.6rem;

	font-size: 1rem;
	font-weight: 400;
}

.textWrapper .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #fff transparent transparent transparent;
}

.textWrapper:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipLink {
	margin-top: 0.3rem;
	color: rgb(30, 103, 238);
	text-decoration: underline;
}

.conditionBadgesBox {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
}

.conditionBadgesTranscript {
	font-weight: 400;
	font-size: 1rem;
	line-height: 26px;
	color: #808080;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	& > span {
		color: #363636;
		font-weight: 500;
		font-size: 1.1rem;
	}
}

.notAvailable {
	position: absolute;
	transform: rotate(15deg);
	background-color: rgba(252, 181, 59, 0.6);
	padding: 0.5rem;
	left: 15%;
}

.notAvailableText {
	font-size: 1.5rem;
	color: #808080;
}

@media (max-width: 1400px) {
	.notAvailableText {
		font-size: 1.3rem;
	}
}

@media (max-width: 1200px) {
	.notAvailable {
		left: 10%;
	}

	.notAvailableText {
		font-size: 1rem;
	}
}

@media (max-width: 992px) {
	.notAvailable {
		left: 20%;
	}

	.notAvailableText {
		font-size: 1.5rem;
	}
}

@media (max-width: 767px) {
	.notAvailable {
		left: 15%;
	}
}

@media (max-width: 600px) {
	.notAvailable {
		left: 10%;
	}

	.notAvailableText {
		font-size: 1rem;
		text-align: center;
	}
}

.buttonWrapper {
	margin: 0 auto;
	width: max-content;

	margin-bottom: 2rem;
}

.idCardLoader {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	margin-top: 0.5rem;
}

.galleryWrapperFixed {
	position: sticky;
	top: 6rem;
	height: max-content;
}

@media (max-width: 992px) {
	.galleryWrapperFixed {
		position: relative;
		top: 0;
	}

	.singleInventoryContent {
		margin-top: 0;
	}
}

.text {
	color: #808080;
	margin-top: 1.3rem;
	margin-bottom: 1.3rem;
}
