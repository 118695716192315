.addPhotoComponent {
	display: flex;
	flex-direction: column;
}
.galleryArea {
	margin-top: 15px;
}
.addIcon {
	height: 35px;
	width: 35px;
}
.removeIcon {
	float: right;
	height: 35px;
	width: 35px;

	background-color: #fcb53b;
	transition: all 0.3s;

	&:hover {
		background-color: #b17000;
	}
}
.addButton {
	margin-top: 5px;
	margin-left: 5px;
	float: left;
	width: 110px;
	height: 90px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.photo {
	margin-top: 5px;
	margin-left: 5px;
	float: left;
	width: 110px;
	height: 90px;
	border-style: dashed;
	border-color: #d8d8d8;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.draggableZone {
	margin-top: 15px;
	padding-left: 20px;
	padding-right: 20px;
	border-style: dashed;
	border-color: #fcb53b;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	text-align: center;
}

.draggableZoneCanDropFile {
	border-color: #81c784;
}

.draggableZoneDisabled {
	color: #d8d8d8;
	border-color: #d8d8d8;
	cursor: default;
}
