.searchBar {
	width: 100%;
	display: flex;
	border-radius: 0.3125rem 0 0 0.3125rem;
	background-color: white;
	padding: 0;

	.iconSearch {
		position: absolute;
		top: 50%;
		left: 1.25rem;
		transform: translateY(-50%);
		width: 1rem;
	}

	input {
		padding: 0rem 1.25rem 0rem 3.375rem;
		flex-grow: 1;
		background-color: rgba(99, 99, 99, 0.03);
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-right: 0;
		border-radius: 0.3125rem 0 0 0.3125rem;
		transition: all 0.3s;
		width: 100%;
		height: 100%;
		font-size: 1rem;
		&:focus {
			outline: none;
			border-right: 0;
			border-left: 2px solid #fcb53b;
			border-bottom: 2px solid #fcb53b;
			border-top: 2px solid #fcb53b;
		}
	}
	button.search {
		width: 9.375rem;
		font-size: 1.125rem;
		border-radius: 0;
	}
}

.searchBarCompact {
	@extend .searchBar;

	input {
		padding: 1rem;
		height: 3.2rem;
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: 0.3125rem;
		&:focus {
			border: 2px solid #fcb53b;
		}
	}

	button.search {
		width: 4rem;
		font-size: 1rem;
		border-radius: 0;
		position: relative;
		height: 3.2rem;
	}

	.iconSearch {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& path {
			fill: #fff;
		}
	}
}

@media (max-width: 576px) {
	.searchBar {
		margin-bottom: 0;
		padding: 1rem;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		display: flex;
		top: 0;
		border-radius: 0;

		input {
			padding: 10px 10px 10px 20px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			border-radius: 0;
			width: 100%;
			height: 3.1rem;
		}

		.iconSearch {
			left: 26px;
			display: none;
		}

		button.search {
			width: 65px;
			padding: 0;
			font-size: 15px;
			border-radius: 0;
			height: 3.1rem;
		}
	}
}

///
.searchBarSmall {
	width: 100%;
	display: flex;
	padding: 1rem;
	input {
		box-sizing: border-box;
		padding: 0.5rem;
		flex-grow: 1;
		background-color: #ffffff;
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: 0.3125rem;
		transition: 0.2s all;

		font-size: 1rem;
		font-weight: 500;
		line-height: 19px;
		&:focus {
			border: 2px solid #4b9ad4;
		}
	}
}

.button {
	padding: 0.5rem;
	margin-left: 1rem;
	background-color: #fcb53b;
	border-radius: 0;
	cursor: pointer;
}

.searchBarCompact {
	@extend .searchBar;

	input {
		padding: 1rem;
	}
}

@media (max-width: 576px) {
	.searchBarSmall {
		margin-bottom: 0;
		padding: 0 0 0 25px;
		width: 100%;
		// box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		display: flex;
		top: 0;

		input {
			margin-right: 0;
			// padding: 10px 10px 10px 40px;
			border: 1px solid #efefef;
			border-radius: 3px;
			background-color: #efefef;
			transition: all 0.3s;
			color: #909090;
			font-weight: 350;
		}

		input:hover,
		input:focus {
			border: 1px solid #909090;
		}

		.iconSearch {
			left: 26px;
		}

		button.search {
			min-width: 80px;
			padding: 10px 0;
			font-size: 15px;
			border-radius: 0;
		}
	}
}

.searchBarSmallContainer {
	display: flex;
	justify-content: space-between;
	padding: 0;
	margin: 0;
	width: 100%;
	margin-left: 25px;
	//margin-right: 25px;
}

.searchBarSmallDouble {
	width: 7rem;
	display: flex;
	padding-bottom: 1rem;
	align-items: flex-start;
	flex-direction: column;
	margin-right: 3rem;
}

.input {
	padding: 0.5rem;
	flex-grow: 1;
	background-color: #ffffff;
	border: 2px solid rgba(0, 0, 0, 0.1);
	border-radius: 0.3125rem;
	width: 100%;
	transition: 0.3s all;

	font-size: 1rem;
	font-weight: 500;
	line-height: 19px;

	&:hover,
	&:focus {
		border: 2px solid #4b9ad4;
	}
}

@media (max-width: 576px) {
	.searchBarSmallDouble {
		flex-direction: column-reverse;
		padding: 0;
		& > input {
			background-color: #efefef;
			border: 1px solid #efefef;
			font-weight: 350;
			width: 130px;
			color: #909090;
		}

		&:hover > input {
			border: 1px solid #909090;
		}
	}

	.searchBarSmallDouble {
		width: 100%;
		display: block;
		padding-bottom: 0;
		margin-right: 0;
	}

	.searchBarSmallDouble:last-child {
		display: flex;
		align-items: flex-end;
	}
}
.searchBarPlaceholder {
	color: black;
	font-size: 1rem;
}

.iconSearchBox {
	display: flex;
	align-items: center;
	background-color: #fcb53b;
}

.cleanButton {
	width: 50%;
	height: 3.1rem;
}

.iconDelete {
	& path {
		fill: black;
	}
}
.iconSearchBoxMob {
	display: none;
}

@media (max-width: 576px) {
	.iconSearchBox {
		display: none;
	}

	.iconSearchBoxMob {
		display: flex;
		align-items: center;
		background-color: #fcb53b;
	}

	.iconSearchMob {
		height: 3.1rem;
		margin: 0;
	}

	.iconSearchMobDelete {
		& path {
			fill: rgb(83, 83, 83);
		}
	}

	// .search {
	// 	height: 100%;
	// 	margin: 0;
	// }
}

.iconSearchThir {
	& path {
		fill: white;
	}
}

.search {
	height: 3.1rem;
}

.searchBarInput {
	height: 3.1rem;
	width: 100%;
}

@media (max-width: 576px) {
	.searchBarSmallDouble {
		margin-right: 0;
	}
}

.searchCompactbutton {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

@media (max-width: 340px) {
	.searchBarSmallContainer {
		flex-direction: column;
	}

	.searchBarSmallDouble:last-child {
		display: flex;
		align-items: flex-start;
		margin-top: 10px;
	}
}
