@keyframes appear {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
}

.statesWindowsConteiner {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	text-align: center;
	z-index: 9999;
	transition: all 0.5s;
	animation: appear 350ms ease-in 1;

	backdrop-filter: saturate(90%) blur(3px);
}

.statesWindowsModal {
	position: absolute;
	display: flex;
	flex-direction: column;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: all 0.5s;

	height: 70%;
	width: 20rem;
	overflow: auto;

	background-color: #fff;
	border-radius: 20px;
}

.badgeContainer {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.statesWindowsModalHeader {
	display: flex;
	position: sticky;
	width: 100%;
	padding: 1rem;
	top: 0;
	justify-content: space-between;
	align-items: center;
	background-color: #fff;
	z-index: 300;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

	& > p {
		font-size: 1.2rem;
		font-weight: 500;
		color: #fcb53b;
	}
}

.statesWindowsModalFooter {
	display: flex;
	position: sticky;
	width: 100%;
	bottom: 0;
	padding: 1rem;
	justify-content: center;
	background-color: #feb53d;
	color: black;
	font-size: 1.2rem;
	font-weight: 500;
	align-items: center;
	z-index: 300;
	cursor: pointer;
	box-shadow: 8px 4px 0px rgba(0, 0, 0, 0.1);
	transition: all 0.5s;
}

.closeBtn {
	font-size: 1.25rem;
	color: #b3b3b3;
	cursor: pointer;
	z-index: 250;
	cursor: pointer;
	transition: all 0.5s;

	&:hover {
		color: #feb53d;
	}
}

.iconSearch {
	width: 1.5rem;
	margin-right: 1rem;
	& path {
		transition: all 0.3s;
		fill: black;
	}
}

.statesWindowsModalFooter {
	transition: all 0.3s;

	& > div {
		transition: all 0.3s;
	}

	&:hover {
		background-color: white;
		box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
	}

	&:hover .iconSearch {
		& path {
			fill: #feb53d;
		}
	}

	&:hover > div {
		color: #feb53d;
	}
}

.onFormSubmit {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
}
