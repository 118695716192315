.dropdownBackdrop,
.dropdownWrapper {
	pointer-events: none;
}

.dropdownBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.05);
	z-index: 98;
	opacity: 0;
	visibility: hidden;
	transition-property: opacity, visibility;
	transition-duration: 300ms;
	transition-timing-function: ease-in-out;
}

.dropdownWrapper {
	display: none;
	width: 365px;
	max-height: 365px;
	padding: 3px 15px;
	background: #ffffff;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	overflow-y: auto;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99;
}

.isOpen {
	.dropdownBackdrop,
	.dropdownWrapper {
		pointer-events: all;
	}

	.dropdownBackdrop {
		opacity: 1;
		visibility: visible;
	}

	.dropdownWrapper {
		display: block;
	}
}

.childTitle,
.parentTitle {
	font-family: Roboto;
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 20px;
}

.childTitle {
	font-style: normal;
	letter-spacing: -0.02em;
	color: #666666;
}

.parentTitle {
	font-weight: bold;
	letter-spacing: -0.015em;
	color: #000000;
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	margin: 10px 0;

	label {
		margin-bottom: 0 !important;
	}
}

.disabled label {
	opacity: 0.5;
}

.divider {
	border-bottom: 1px solid #ddd;
}

@media (max-width: 640px) {
	.dropdownWrapper {
		width: 100%;
		height: 50vh;
		top: -50%;
	}
}
