.underlayer {
	width: 100%;
	height: 100%;
	//background-color: #000;
	//opacity: 0.6;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 150;

	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: saturate(90%) blur(3px);
	&.hidden {
		opacity: 0;
		pointer-events: none;
	}
}
