.uploadManifest {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	position: relative;
	overflow: hidden;
	border-radius: 0.3125rem;
	margin-bottom: 1.25rem;

	input[type="file"] {
		display: none;
	}

	.iconPlusWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 50px;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #fcb53b;

		.border {
			width: 22px;
			height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid #fff;
			border-radius: 50%;
		}

		.iconPlus {
			color: #fff;
			font-size: 0.75rem;
		}
	}

	button {
		padding-left: 4.625rem;
	}
}

.buttonWrapper {
	display: flex;
	align-items: center;
	margin-right: 1rem;
	cursor: pointer;
}

.manifestFile {
	cursor: pointer;

	a {
		text-decoration: underline;
	}

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 300px;
		text-decoration: underline;
		color: #fcb53b;
	}

	.iconTimes {
		color: red;
		margin-left: 0.5rem;
	}
}
