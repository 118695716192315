@keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes fadeInUp {
	from {
		transform: translate3d(0, 40px, 0);
	}

	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
}

.animatedFadeInUp {
	opacity: 0;
}

.fadeInUp {
	opacity: 0;
	animation-name: fadeInUp;
	-webkit-animation-name: fadeInUp;
}

.inventoryCard {
	display: flex;
	flex-direction: column;
	width: 18.75rem;
	transition: all 0.3s;
	height: auto;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 20px;
	cursor: pointer;

	&:hover {
		// transform: scale(1.05);
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
	}

	&:hover .inventoryImg {
		transform: scale(1);
	}

	.inventoryDescription {
		display: flex;
		flex-direction: column;
		padding: 1.875rem 1.6rem 0rem 1.6rem;
		line-height: 1.625rem;
	}

	.inventoryTitle {
		margin-top: 15px;
		margin-bottom: 15px;
		text-align: left;
		// font-size: 14px;
		// font-weight: 400;

		font-weight: 400;
		font-size: 1rem;
		line-height: 26px;

		height: max-content;
		overflow: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@media (max-width: 720px) {
	.image {
		width: 250px;
		margin: 0 auto;
	}
}

@media (max-width: 576px) {
	.inventoryCard {
		margin-left: 0;
		max-width: 300px;
		display: flex;
		align-items: center;

		&:hover {
			transform: scale(1);
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
		}
	}
}

@media (max-width: 320px) {
	.inventoryCard {
		max-width: 270px;
	}
}

@media (max-width: 300px) {
	.inventoryCard {
		max-width: 220px;
	}
}

.imageHover {
	transition: all 0.3s;
	transform: scale(0.95);
}

.inventoryCardSlider {
	transform: scale(1);

	&:hover {
		transform: scale(1);
		box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);

		.imageHover {
			transition: all 0.3s;
			transform: scale(1);
		}
	}

	.inventoryTitle {
		overflow: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

@media (max-width: 576px) {
	.inventoryCard {
		margin-left: 0;
	}
}

.imgWrapper {
	position: relative;

	.infoBtn {
		position: absolute;
		top: 1.25rem;
		right: 1.25rem;
		padding: 5px;
		z-index: 1;
	}
}

.infoBtn .tooltiptext {
	visibility: hidden;
	width: max-content;
	background-color: rgb(243, 196, 65, 0.5);
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: -85%;
	right: 125%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 0.6rem;

	font-size: 0.7rem;
	font-weight: 500;

	opacity: 0;
}

.infoBtn .tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 50%;
	right: -5%;
	margin-left: -5px;
	transform: rotate(270deg);
	border-width: 5px;
	border-style: solid;
	border-color: rgb(243, 196, 65, 0.5) transparent transparent transparent;
}

.infoBtn:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipMessage {
	width: 12rem;
	font-size: 0.9rem;
}

.inventoryImg {
	transform: scale(1.1);
	transition: all 0.3s;
}

.inventoryImgLoader {
	width: 300px;
	height: 300px;

	border: 1px solid red;
}

.test {
	width: 100%;
	height: 100%;
	background: gold;
	box-shadow: 0 -200px 100px -120px crimson inset;
	animation: background 3s infinite alternate;
}

@keyframes background {
	50% {
		background: skyblue;
		box-shadow: 0 -200px 100px -100px yellowgreen inset;
	}
}
