$white: #fff;

.mobileFilter {
	display: none;
}

@media (max-width: 576px) {
	.testNew {
		display: flex;
		flex-direction: column;
		position: fixed;
		//width: (calc(100% - 50px));
		width: 100%;
		height: (calc(100% - 50px));
		top: 0;
		left: 0px;
		z-index: 850 !important;
		transform: translateX(0);
		transition: transform ease 0.3s;
		background-color: #fff;
		overflow: scroll;

		&.hidden {
			left: -80px;
			transform: translateX(calc(-100%));
		}
	}

	.buttonHeaderBox {
		backdrop-filter: blur(10px);

		background: rgba(241, 241, 241, 0.7);
		padding: 0.5rem 1rem 0.5rem 0.5rem;
		display: flex;
		justify-content: space-between;
		position: absolute;
		z-index: 200;

		bottom: 0;
		right: 0;
	}

	.button {
		display: flex;
		justify-content: space-around;
		align-items: center;
		font-weight: 400;
		width: max-content;
		height: 40px;
		color: #fff;

		background-color: #4b9ad4;
	}

	.headerWrapper {
		display: flex;
		align-items: center;

		& > div {
			margin: 0 15px 0 0;
			font-weight: 500;
			font-size: 16px;
			line-height: 18.75px;
			color: #363636;
		}

		span {
			height: 1px;
		}
	}

	.headerWrapperToggler {
		width: 30px;
		height: 30px !important;
		cursor: pointer;
		position: relative;
		transition: all 0.5s;
		margin-left: 0.5rem;
		background-color: #f8f0e2;

		&::before {
			position: absolute;
			content: "";
			width: 1rem;
			height: 3px;
			background-color: #fcb53b;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.3s;
		}
		&::after {
			position: absolute;
			content: "";
			width: 3px;
			height: 1rem;
			background-color: #fcb53b;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			transition: transform 0.3s;
		}

		&.isHidden {
			transform: rotate(90deg);
		}

		&.isHidden::after {
		}
		&.isHidden::before {
			visibility: hidden;
		}
	}

	.title {
		font-size: 1.2rem;
	}

	.childrenConteiner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.arrow {
		display: block;
		width: 0.625rem;
		height: 0.625rem;
		border-top: 0.125rem solid #fcb53b;
		border-right: 0.125rem solid #fcb53b;
		transform: rotate(135deg);
		transition: transform 0.3s;
		cursor: pointer;
	}

	.childrenConteiner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.boxCategories {
		background-color: white;
		padding-bottom: 4rem;
	}

	.bottomBox {
		display: flex;
		flex-direction: row;
		position: fixed;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: 60px;
		bottom: 0;
		left: 0px;
		z-index: 850 !important;
		transform: translateX(0);
		transition: transform ease 0.3s;
		background-color: white;
		padding: 10px;
		overflow: scroll;

		&.hidden {
			left: -80px;
			transform: translateX(calc(-100%));
		}
	}

	.buttonHeader {
		font-weight: 400;
		border-radius: 0;
		z-index: 200;
		bottom: 0;
		width: 100%;
		max-width: 370px;
		color: #ffffff;
	}

	.box {
		padding: 10px;
	}

	.active {
		display: none;
	}

	.noActive {
		display: inline-block;
	}
}
