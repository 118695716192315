.inventoryContainer {
	.inventoryContent {
		display: grid;
		grid-template-columns: 18.75rem auto;
	}

	.cardsWrapper {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-left: 1rem;
		justify-content: center;
	}

	.paginationInventory {
		width: 100%;
		justify-content: center;
		margin-top: 3.125rem;
		margin-bottom: 7.5rem;
	}
}

@media (min-width: 577px) and (max-width: 990px) {
	.inventoryContainer {
		width: 90%;
	}

	.inventoryContainer {
		.inventoryContent {
			display: grid;
			grid-template-columns: 50% 50%;
		}
	}
}

.searchBarWrapper {
	background-color: transparent;
	position: -webkit-sticky;
	position: sticky;
	top: 80px;
	height: 50px;
	margin-bottom: 50px;

	z-index: 50;
}

.containetItems {
	display: flex;
	flex-wrap: wrap;
	//justify-content: space-around;
}

.inventoryContentButton {
	font-size: 1rem;
	font-family: inherit;
	font-weight: 500;
	padding: 0.875rem 1.5rem;
	cursor: pointer;
	width: 100%;
	margin-bottom: 20px;
	color: #363636;
	background-color: #fcb53b;
	transition: all 0.3s;

	&:hover {
		background-color: #f8c771;
	}
}

.containetItemsBox {
}
.containetItems {
	margin-bottom: 2rem;
}

.containetItemspagination {
	display: flex;
	margin: 0 auto;
	width: max-content;
}

.checkboxes {
	display: flex;
	flex-direction: column;
	font-size: 0.9375rem;
	font-weight: 400;

	label {
		display: flex;
		padding-left: 1rem;
		padding-bottom: 0.625rem;
		padding-top: 0.625rem;
	}
	input[type="checkbox"] {
		appearance: none;
		background: #fff;
		width: 1.25rem;
		height: 1.25rem;
		margin: 0;
		margin-right: 1rem;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 3px;
		transition: 0.3s;
		cursor: pointer;
		&:checked {
			background: #4b9ad4;
		}
	}
}

.checkboxesitem {
	transition: 0.3s;
	cursor: pointer;
}

.filterTitle {
	display: flex;
	align-items: flex-end;
}

.filterTitleBox {
	display: inline-block;
	padding-left: 0.5rem;
	width: 30%;

	border-bottom: 3px solid #fcb53b;
	margin-top: 1rem;

	& > p {
		font-weight: 400;
		font-size: 1rem;
		color: #636363;
	}
}

.filterTitleLine {
	display: flex;
	border-bottom: 0.0625rem dashed rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 1rem;
}

@media (max-width: 576px) {
	.inventoryContainer {
		.searchBarWrapper {
			display: none;
		}

		.filterSidebarWrapper {
			display: none;
		}

		.inventoryContent {
			display: unset;
			grid-template-columns: unset;
		}

		.cardsWrapper {
			flex-direction: column;
			align-items: center;
			margin: 0 auto;
			width: 100%;
		}
	}

	.inventoryContentButton {
		width: 11rem;
		margin: 0 auto;
		margin-bottom: 2rem;
	}

	.inventoryContent {
		padding: 0;
		margin: 0;
	}

	.containetItems {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
		max-width: 370px;
	}
}

@media (max-width: 365px) {
	.containetItems {
		justify-content: center;
	}
}
