.dialogBackdrop {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999;

	&::before {
		content: "";
		display: block;
		width: inherit;
		height: inherit;
		opacity: 0.5;
		background: #363636;
	}
}

.dialogWrapper {
	position: absolute;
	width: 530px;
	height: 325px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	border-radius: 5px;
}

.dialogContent {
	padding: 40px 52px;
	text-align: center;
}

.title,
.text {
	font-family: Roboto;
	font-style: normal;
	text-align: center;
}

.title {
	margin-top: 20px;
	margin-bottom: 10px;
	font-weight: 500;
	font-size: 30px;
	line-height: 35px;
	color: #fcb53b;
}

.text {
	margin-bottom: 40px;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	color: #636363;
}

.dialogButtons {
	display: flex;
	justify-content: center;
	gap: 1rem;
}

.buttonNo,
.buttonYes {
	width: 150px;
	height: 40px;
	cursor: pointer;
}

.buttonNo {
	color: #fff;
	background: #393939;
}

.buttonYes {
	color: #363636;
	background: #fcb53b;
}

.iconInfoCircle {
	color: #fcb53b;
	font-size: 50px;
}

@media (max-width: 576px) {
	.dialogWrapper {
		position: absolute;
		width: 330px;
		height: 325px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #ffffff;
		border-radius: 5px;
	}
}
