$white: #fff;
$yellow: #fcb53b;

.formContainer {
	// width: 90%;
	//width: 1175px;
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 2.5rem;
	background-color: $white;
	border-radius: 5px;

	//margin: 0 auto;
	.formHeader {
		.titleWrapper {
			position: relative;
		}

		.closeBtn {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 1.25rem;
			color: #b3b3b3;
			cursor: pointer;
			z-index: 250;
			transition: all 0.3s;

			&:hover {
				color: $yellow;
			}
		}

		h3 {
			margin: 0;
			font-size: 1.625rem;
			font-weight: 500;
			color: $yellow;
		}
		p {
			font-size: 1rem;
			font-weight: 400;
			color: #636363;
			margin-bottom: 0;
			margin: 1rem 1rem 1rem 0;
		}
		margin-bottom: 1rem;
	}

	.submitBtn {
		position: relative;
		padding: 1rem;
	}
}

@media (max-width: 640px) {
	.formContainer {
		padding: 1.875rem 1.25rem;
		padding-bottom: 0;
		overflow: scroll;
		margin: 0 auto;
		max-height: 90vh;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 90%;
	}

	.formContent {
		padding-bottom: 1rem;
		margin: 0;
		form {
			display: flex;
			flex-direction: column;
		}

		.submitBtn {
			align-self: center;
		}
	}

	.formHeader {
		z-index: 200;
		background-color: #fff;
	}
}

.submitBtnSucces {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.submitBtnSuccesMessage {
	margin-top: 1.25rem;
	margin-left: 2rem;
	padding: 1rem;
	font-size: 1rem;
	background-color: #fcb53b;
	border: 1px solid #fcb53b;
	transition: all 0.2s;

	margin: 0;
}

@media (max-width: 640px) {
	.submitBtnSucces {
		display: flex;
		flex-direction: column-reverse;
	}

	.submitBtnSuccesMessage {
		width: 100%;
		margin-left: 0rem;
	}
}

.submitBtn {
	position: relative;
}

.reCaptchaV3 {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	opacity: 0;
	cursor: pointer;
}
