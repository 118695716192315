@import "./../../../styles/module-global/colors.module";
@import "./../../../styles/module-global/mixin.module";

.itemBox {
	display: flex;
	flex-direction: column;
	padding: 15px;
	border: 1px solid #f4f4f4;
	box-shadow: 0px 2px 15px -7px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	margin: 15px;
}

.image {
	width: 272px;
	height: 272px;
	margin-bottom: 5px;
	overflow: hidden;
	z-index: 2;
}

.title {
	width: 100%;
	max-width: 272px;
	margin-bottom: 10px;

	& > p {
		margin: 0;
		@include text(18px, 600, 20px, $black);
	}
}

.categories {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 272px;
	margin-bottom: 15px;

	& > p {
		margin: 0;
		padding: 5px;
		width: max-content;
		background-color: $blue;
		@include text(12px, 400, 13.08px, $white);
		margin-right: 5px;
		margin-bottom: 5px;
	}
}

.priceBox {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 272px;
	flex-wrap: wrap;
}

.priceBoxRigth {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	& > p {
		margin: 0;
		@include text(12px, 400, 18.8px, $mediumGray);

		& > span {
			margin-left: 5px;
			@include text(12px, 600, 18.8px, $black);
		}
	}
}

.infoItem {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 272px;
	flex-wrap: wrap;
	margin-top: 10px;
}

.info {
	width: 50%;
	& > p {
		margin: 0;
		@include text(12px, 400, 17px, $mediumGray);
	}
}

.result {
	width: 50%;
	& > p {
		margin: 0;
		@include text(12px, 600, 17px, $black);
	}
}

@media (max-width: 670px) {
	.itemBox {
		margin: 5px;
	}
}

@media (max-width: 576px) {
	.itemBox {
		display: flex;
		flex-direction: column;
		padding: 8px;
		border: 1px solid #f4f4f4;
		cursor: pointer;
		margin: 0 0 20px 0;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
	}

	.image {
		width: 160px;
		height: 160px;
		margin: 0;
		padding: 0;
		z-index: 2;
	}

	.title {
		width: 100%;
		max-width: 160px;
		margin: 10px 0 10px 0;

		& > p {
			margin: 0;
			@include text(12px, 400, 14px, $black);
		}
	}

	.categories {
		margin: 0;
	}
}
