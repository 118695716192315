.imageCropper {
	background-color: black;
	display: flex;
	flex-direction: column;
}

.reactCropContainer {
	max-height: 100%;
}

.reactCrop {
	display: flex;
	flex-direction: column;
	opacity: 1 !important;
	max-height: 90%;
}

.buttons {
	display: flex;
	flex-direction: row;
	height: 50px;
	background-color: #000000;
}

.button {
	width: 50%;
	font-size: 28px;
}

.reactCrop {
	border: 1px solid red;
}
