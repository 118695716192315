@keyframes appear {
	0% {
		opacity: 0;
	}
}

.containerMain {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	width: 800px;

	background-color: #fff;
	animation: appear 400ms ease-in 1;
}

.containerMainHeadert {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
	border-radius: 10px 10px 0px 0px;
	padding: 1rem;
	padding-right: 1.3rem;
	width: 100%;
	background-color: #fff;
	z-index: 99;
	position: sticky;
	top: 0;
	left: 0;
}

.containerBody {
	padding: 1rem;
}

.containerMainTitle {
	margin: 0;
	font-size: 1.625rem;
	font-weight: 500;
	color: #fcb53b;
}

.closeBtn {
	font-size: 1.25rem;
	color: #b3b3b3;
	cursor: pointer;
	z-index: 250;
	transition: all 0.3s;

	&:hover {
		color: #fcb53b;
	}
}

.containerMainLoading {
	color: #fcb53b;
}

@media (max-width: 840px) {
	.containerMain {
		width: 300px;
		max-height: 80vh;
		overflow: scroll;
	}
}
