@import "./../../../styles/module-global/colors.module.scss";
@import "./../../../styles/module-global/mixin.module.scss";

// зум для банера, анімація

// @-webkit-keyframes kenburns-top {
// 	0% {
// 		-webkit-transform: scale(1) translateY(0);
// 		transform: scale(1) translateY(0);
// 		-webkit-transform-origin: 50% 16%;
// 		transform-origin: 50% 16%;
// 	}
// 	100% {
// 		-webkit-transform: scale(1.25) translateY(-15px);
// 		transform: scale(1.25) translateY(-15px);
// 		-webkit-transform-origin: top;
// 		transform-origin: top;
// 	}
// }

// @keyframes kenburns-top {
// 	0% {
// 		-webkit-transform: scale(1) translateY(0);
// 		transform: scale(1) translateY(0);
// 		-webkit-transform-origin: 50% 16%;
// 		transform-origin: 50% 16%;
// 	}
// 	100% {
// 		-webkit-transform: scale(1.25) translateY(-15px);
// 		transform: scale(1.25) translateY(-15px);
// 		-webkit-transform-origin: top;
// 		transform-origin: top;
// 	}
// }

.banner {
	position: relative;
	height: 27.5rem;
	background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 56.35%);
	background-size: contain auto;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
	margin-bottom: 7.5rem;

	margin-top: 86px;

	.bannerContent {
		max-width: 27rem;
		z-index: 2;

		h2 {
			margin: 0;
			line-height: 3rem;
			padding-bottom: 1.25rem;
			position: relative;
			&::before {
				position: absolute;
				content: "";
				width: 30px;
				height: 6px;
				left: 0;
				bottom: 0;
				background-color: $orange;
			}
		}
	}

	.description {
		font-weight: 400;
		line-height: 1.5rem;
		margin-bottom: 3.125rem;
		margin-top: 1.25rem;
	}

	.bannerButton {
		width: 10rem;
		&:last-of-type {
			margin-right: 1rem;
		}
	}
}

.background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 56.35%);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.image {
	// -webkit-animation: kenburns-top 5s ease-out both;
	// animation: kenburns-top 5s ease-out both;
}

@media (max-width: 1276px) {
	.banner {
		position: relative;
		height: 20rem;
	}
}

@media (max-width: 576px) {
	.banner {
		margin-bottom: 4.375rem;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
		height: 297px;
	}

	.background {
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
		background-color: rgba(4, 52, 129, 0.502);
	}

	.banner {
		.bannerContent {
			h2 {
				margin: 0;
				padding: 0;
				margin-bottom: 15px;
				&::before {
					left: 0;
					bottom: -10px;
					background-color: $orange;
				}
			}
		}
		.description {
			margin: 0;
			padding: 0;
			@include text(16px, 400, 22px, $white);
			margin: 0;
			padding: 0;
			margin-top: 15px;
		}
	}

	.bannerContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin-right: auto;
		margin-left: auto;
		height: max-content;

		h2 {
			@include text(26px, 600, 30px, $white);
			margin: 0;
			padding: 0;
		}

		h2::before {
			bottom: 15px;
			left: 50% !important;
			transform: translateX(-50%);
			margin: 0;
			padding: 0;
		}
	}

	.buttonsWrapper {
		display: flex;
		flex-direction: column;

		& > div {
			margin-bottom: 1rem;
		}
	}
}
